import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import AddressSearchInput from "components/common/AddressSearchInput"
import Image from 'components/common/Image';

import heroSun from 'images/homepage/flowmoji-sun.svg';
import heroSwan from 'images/homepage/flowmoji-swan.svg';

import { isLpgDemergerOn } from 'constants/global';

function HomepageHeroBanner (props) {

    return (
        <>
            <LayoutPanel //theme="mer-theme--cyan"
                         padding="mer-pt-lg"
                         background="mer-bg--primary-cyan"
                         id="homepage-hero-banner">
                <div className="container">
                    <div className="row mer-panel align-items-end justify-content-around mer-theme--cyan">
                        <div className="col-10 col-sm-8 col-md-8 col-lg-7 col-xl-6 mer-text--size-lg">
                            <div className="content text-center">
                                <h1 className="mb-0">Great value gas.</h1>
                                <h2 className="mer-typography--headline1 mt-0">The WA way.</h2>
                                <h5>Enjoy award-winning local service and round-the-clock value with WA’s born and bred natural gas provider.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mer-panel align-items-end justify-content-center mer-mt-de feature-row">
                        <div className="col-12 col-sm-10 col-md-9 col-lg-7 mer-card mdc-card p-relative hero-searchbox">
                            <form className="my-0 mer-py-de mer-px-lg text-center">
                                <fieldset className="mer-text--size-lg">
                                    <legend>Sign up with us in a few easy steps.</legend>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-lg-11">
                                            <AddressSearchInput searchButtonText="Search"
                                                                showLabel="false"
                                                                isSsfRedirect={isLpgDemergerOn} />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="d-flex align-items-center justify-content-center content" style={{'marginBottom':'-1em'}}>
                                    <p className="my-0" style={{'fontWeight':'500'}}>No account setup or exit fees</p>
                                    <Image className="my-0 mer-ml-sm" style={{'width':'20%', 'maxWidth':'3em'}} src="/icons/flowmoji/flowmoji-sunglasses.svg" alt="sunglasses" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="sun">
                        <img className="mer-panel__image"
                            src={heroSun}
                            alt="Sun flowmoji"
                        />
                    </div>
                    <div className="swan">
                        <img className="mer-panel__image"
                            src={heroSwan}
                            alt="Swan flowmoji"
                        />
                    </div>
                </div>
            </LayoutPanel>
        </>
    );
}

export default HomepageHeroBanner;